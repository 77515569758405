.page-side-nav {
  align-items: stretch;
  background: var(--color-primary-contrast);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  overflow-y: auto;
  color: var(--color-primary); }
  .page-side-nav #header {
    position: relative;
    display: block;
    min-height: 96px;
    box-shadow: 0 0 10px 0 var(--color-shadow, rgba(0, 0, 0, 0.2)); }
    .page-side-nav #header #logo-container {
      position: absolute;
      top: .5rem; }
  .page-side-nav .side-nav {
    background: var(--color-primary);
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    height: 3rem;
    position: relative;
    width: 100%;
    z-index: 4; }
    .page-side-nav .side-nav .side-nav-item {
      background-position: 1rem 50%;
      background-repeat: no-repeat;
      background-size: 18px;
      cursor: pointer;
      height: 3rem;
      overflow: hidden;
      padding: 1rem .5rem 1rem 2.5rem;
      text-align: center;
      transition: background-color .2s ease; }
      .page-side-nav .side-nav .side-nav-item.notifications {
        background-image: url("../../../assets/images/icons/side-nav/notifications.svg"); }
      .page-side-nav .side-nav .side-nav-item.dashboard {
        background-image: url("../../../assets/images/icons/side-nav/dashboard.svg");
        background-size: 22px 19px; }
      .page-side-nav .side-nav .side-nav-item.your-cases {
        background-image: url("../../../assets/images/icons/side-nav/your-cases.svg"); }
      .page-side-nav .side-nav .side-nav-item:hover, .page-side-nav .side-nav .side-nav-item.active {
        background-color: var(--color-primary-contrast); }
        .page-side-nav .side-nav .side-nav-item:hover.notifications, .page-side-nav .side-nav .side-nav-item.active.notifications {
          background-image: url("../../../assets/images/icons/side-nav/notifications-hover.svg"); }
        .page-side-nav .side-nav .side-nav-item:hover.dashboard, .page-side-nav .side-nav .side-nav-item.active.dashboard {
          background-image: url("../../../assets/images/icons/side-nav/dashboard-hover.svg");
          background-size: 22px 19px; }
        .page-side-nav .side-nav .side-nav-item:hover.your-cases, .page-side-nav .side-nav .side-nav-item.active.your-cases {
          background-image: url("../../../assets/images/icons/side-nav/your-cases-hover.svg"); }
    .page-side-nav .side-nav .side-nav-item-content {
      background: #FFFFFF;
      box-shadow: 0 10px 10px 0 var(--color-shadow, rgba(0, 0, 0, 0.2));
      left: 0;
      overflow-y: auto;
      padding: 1rem 0;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 2; }
  .page-side-nav .content-area {
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: hidden; }
    .page-side-nav .content-area > div {
      height: 100%;
      overflow: auto;
      width: 100%; }
  @media screen and (min-width: 48em) {
    .page-side-nav {
      flex-direction: row; }
      .page-side-nav .side-nav {
        flex-direction: column;
        height: auto;
        padding-top: 1.5rem;
        width: 3rem; }
        .page-side-nav .side-nav .side-nav-item {
          background-position: 50% 1rem;
          height: auto;
          padding: 2.5rem 0 .5rem;
          text-align: center;
          width: 100%; }
        .page-side-nav .side-nav .side-nav-item-content {
          bottom: 0;
          box-shadow: 10px 0 10px 0 var(--color-shadow, rgba(0, 0, 0, 0.2));
          left: 100%;
          padding: 1.5rem 0;
          top: 0;
          width: 400px; } }
  .page-side-nav .form-group label,
  .page-side-nav a, .page-side-nav .pseudo-link {
    color: var(--color-secondary); }
  .page-side-nav .pure-menu-link {
    color: var(--color-secondary); }
    .page-side-nav .pure-menu-link:hover, .page-side-nav .pure-menu-link:focus {
      color: var(--color-primary-contrast); }

.counter {
  background: #c80a0a;
  border-radius: 1.5rem;
  color: #fff;
  display: inline-block;
  font-size: 0.875rem;
  height: 1.5rem;
  line-height: 1.5rem;
  min-width: 1.5rem;
  padding: 0 .25rem;
  text-align: center; }
  .counter-small {
    border: 1px solid var(--color-primary-contrast);
    font-size: 0.625rem;
    height: 1rem;
    line-height: 15px;
    min-width: 1rem; }
