#header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem; }
  @media screen and (min-width: 48em) {
    #header {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem; } }

.logo-main {
  width: 120px;
  max-width: 100%;
  float: left; }
  @media screen and (min-width: 48em) {
    .logo-main {
      width: 164px; } }
