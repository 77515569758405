.navbar-toggle {
  background: none;
  border: none;
  display: block;
  display: inline-block;
  float: right;
  margin-left: 1rem; }
  .navbar-toggle .icon-bar {
    background: darkgray;
    border-radius: 0.3125rem;
    height: 0.3125rem;
    width: 2rem; }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 0.3125rem; }

.has-push-right .navbar-toggle {
  display: none; }

@media screen and (min-width: 64em) {
  .navbar-toggle {
    display: none; } }

body.has-active-menu {
  overflow: hidden; }

.main-nav-wrapper.has-push-right {
  overflow: hidden;
  transform: translateX(-100%); }

.main-nav-mask {
  background-color: #3e3e3e;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
  width: 0;
  z-index: 100; }
  .main-nav-mask.is-active {
    height: 100%;
    opacity: 0.7;
    transition: opacity 0.3s;
    width: 100%; }

.main-nav {
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  padding: 0 1rem;
  position: fixed;
  transition: transform 0.3s;
  z-index: 200; }
  .main-nav .main-nav-menu-list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; }
    .main-nav .main-nav-menu-list .main-nav-menu-item {
      border-bottom: solid 1px #E5E5E5;
      display: block; }
    .main-nav .main-nav-menu-list .main-nav-menu-link {
      color: #404040;
      display: block;
      font-family: "Open Sans", sans-serif;
      font-size: 1.5em;
      font-size: 1.125rem;
      padding: 2rem .5em; }
  .main-nav .main-nav-close-btn {
    background: transparent url("../../src/assets/icons/nav-close.svg") no-repeat;
    background-size: 100%;
    border: none;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 1.5rem; }
    .main-nav .main-nav-close-btn:focus {
      outline: none; }
  .main-nav.main-nav-push-right {
    height: 100%;
    overflow-y: scroll;
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: 100%; }
    .main-nav.main-nav-push-right.is-active {
      transform: translateX(0); }

@media screen and (min-width: 48em) {
  .main-nav.main-nav-push-right {
    transform: translateX(300px);
    width: 300px; }
  .main-nav-wrapper.has-push-right {
    transform: translateX(-300px); } }
