.error-page {
  height: 100%; }
  .error-page h1 {
    font-size: 2.75rem;
    line-height: 3.5rem;
    margin-bottom: 0; }
    .error-page h1.error-numbered {
      font-size: 6.5rem;
      line-height: 6.5rem; }
  .error-page .error-icon {
    background: var(--color-gray-light);
    border-radius: 3rem;
    height: 6rem;
    line-height: 6rem;
    text-align: center;
    width: 6rem; }
    .error-page .error-icon img {
      display: inline-block;
      height: auto;
      width: 28px; }

.width-100 {
  width: 100%; }
