.popup-window {
  background: var(--color-primary-contrast);
  color: var(--color-primary);
  box-sizing: border-box;
  margin-top: 5rem;
  padding: 2rem 1rem; }
  .popup-window .form-group label {
    color: var(--color-primary);
    text-align: left; }
  .popup-window .popup-content {
    text-align: center; }
  .popup-window .popup-title {
    font-size: 1.5rem; }
  @media screen and (min-width: 35.5em) {
    .popup-window {
      left: 50%;
      margin-left: 0;
      margin-right: 0;
      padding: 5rem 6rem;
      position: absolute;
      transform: translate(-50%); }
      .popup-window .popup-content {
        margin-left: auto;
        margin-right: auto;
        width: 26.25rem; }
      .popup-window .popup-title {
        font-size: 1.75rem; } }
